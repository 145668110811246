<template>
    <div class="headView">
        <div class="headWidth">
            <div class="headTime">
                北京时间：{{ timeToBeiJin }}
            </div>

            <div class="loginOut">
                <div class="headImg">
                    <div class="imgBox">
                        <img src="../assets/headImg.gif" alt="">
                        <i class="el-icon-caret-bottom"></i>

                        <div class="out" @click="logout()">
                            退出登录
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="headHeight">
            <div class="headTitle">
                <h3>小Q后台管理系统</h3>
            </div>
            <div 
                class="menuBox" 
                v-for="(item, key) in menuArr" 
                :key="key"
                @click="routeTab(key)">
                <router-link to="">
                    <div class="routerBox" :class="[item.select ? 'backSelect' : '']">
                        <div class="menuIco">
                            <span :class="item.ico"></span>
                        </div>
                        <div class="menuTitle">
                            {{ item.title }}
                        </div>
                    </div>
                </router-link>

            </div>
        </div>
    </div>
</template>

<script>
import setTime from '../utils/index'

export default {
    name: "headView",
    data() {
        return {

            timeToBeiJin: '',

            menuArr: [
                {
                    title: "活动管理",
                    ico: "el-icon-s-home",
                    select: false
                }, {
                    title: "活动结果",
                    ico: "el-icon-s-help",
                    select: false
                }, {
                    title: '发布活动',
                    ico: 'el-icon-edit',
                    select: false
                }
            ],

        }
    },

    props: ['headShow'],

    mounted() {
        this.menuArr[this.routeChekct()]['select'] = true;
        this.getTime()
    },

    methods: {
        getTime() {
            this.timeToBeiJin = setTime(new Date, 1)
            let setTimeFun = setTimeout(() => {
                clearTimeout(setTimeFun)
                this.getTime()
            }, 1000)

        },

        logout() {
            this.$router.replace({
                path: '/login'
            })
        },

        routeTab(key) {
            this.setClass(key)

            switch (key) {
                case 0:
                    if (this.$route.fullPath == '/') return
                    this.$router.push('/')
                    break;
                case 1:
                    if (this.$route.fullPath == '/about') return
                    this.$router.push('/about')
                    break;
                case 2:
                    if (this.$route.fullPath == '/add') return
                    this.$router.push('/add')
                    break;
                default:
                    break;
            }
        },

        routeChekct() {
            switch (this.$route.path) {
                case '/':
                    return 0;
                case '/about':
                    return 1;
                case '/add':
                    return 2;
            }
        },

        setClass(index) {
            this.menuArr.forEach((item, index) => {
                this.menuArr[index]['select'] = false
            })
            this.menuArr[index]['select'] = true
        }
    },

}
</script>

<style lang="less" scoped>
.headView {
    width: 100%;
    height: 100%;

    .headWidth {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: #fff;
        box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
        z-index: 98;
    }

    .headHeight {
        position: absolute;
        left: 0;
        top: 0;
        width: 210px;
        height: 100%;
        background-color: #304156;
        color: rgb(191, 203, 217);
        z-index: 99;
        overflow: hidden;

        .headTitle {
            font-size: 20px;
            color: #eee;
            text-align: center;
            font-weight: bold;
        }
    }
}

.menuBox {
    height: 56px;
    line-height: 56px;
    cursor: pointer;
    .menuIco {
        margin-right: 16px;
        font-size: 16px;
    }

    .menuTitle{
        font-size: 14px;
    }

    .routerBox {
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        width: auto;
        height: 56px;
        color: #bfcbd9;
    }
}

.menuBox:hover {
    background-color: #1f2d3d;
}

.backSelect {
    color:rgb(64, 158, 255) !important;
    background-color: #1f2d3d;
}

.headWidth {
    .headTime {
        float: left;
        margin-left: 230px;
        height: 50px;
        line-height: 50px;
        font-weight: bold;
        color:#606266;
    }

    .loginOut {
        float: right;
        height: 100%;
        line-height: 50px;

        .headImg {
            margin-right: 30px;
            display: inline-block;
            position: relative;
            color: #606266;
            font-size: 14px;

            img {
                border-style: none;
                cursor: pointer;
                width: 40px;
                height: 40px;
                border-radius: 10px;
            }

            i {
                cursor: pointer;
                position: absolute;
                right: -20px;
                top: 25px;
                font-size: 12px;
                font-family: element-icons!important;
                font-style: normal;
                font-weight: 400;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                vertical-align: baseline;
                display: inline-block;
                -webkit-font-smoothing: antialiased;
            }
        }

        .imgBox {
            margin-top: 5px;
            position: relative;

            .out {
                display: none;
                position: absolute;
                top: 40px;
                left: -20px;
                width: 80px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #606266;
                font-size: 14px;
                background-color: #fff;
                border-radius: 5px;
                cursor: pointer;
                box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            }
        }

        .headImg:hover .out {
            display: block !important;
        }

        .out:hover {
            color: rgb(64, 158, 255) !important;
        }
    }
}

.router-link-active{
    display: block;
    width: 100%;
    height: 56px;
    text-decoration: none;
    // color: #409eff;
    // background: #1f2d3d;
}
</style>