import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './config'
import request from './utils/request'

Vue.config.productionTip = false
Vue.prototype.request = request

router.beforeEach((to, from, next) => {
  // document.title = to.meta.title || '';
  const user = JSON.parse(sessionStorage.getItem('land'));
  if (!user && to.path !== '/login') {
      next('/login');
  } else {
      next();
  }
});


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
